import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "フロントエンドエンジニアが Ionic を触ってみた〜メドレー TechLunch〜",
  "date": "2017-11-24T03:00:00.000Z",
  "slug": "entry/2017/11/24/120000",
  "tags": ["medley"],
  "hero": "./2017_11_24.png",
  "heroAlt": "ionic"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/83820"
      }}>{`大岡`}</a>{`です。オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」の開発を担当しているエンジニアです。2017 年 6 月にメドレーに転職してきて初めて気づきましたが、僕は人見知りでした。`}</p>
    <p>{`メドレーでは、定期的に TechLunch という社内勉強会を実施しています。今回僕が担当になりましたので、その時の内容をご紹介させていただければと思います。テーマは「フロントエンドエンジニアが Ionic を触ってみた」です。`}</p>
    <p>{`色々な事情を考えずに好き放題言っています。個人的にウェブアプリが好きということもありウェブアプリよりの偏ったことを書いていると思います。ご了承ください。`}</p>
    <h1>{`なぜ Ionic を触ろうと思ったか`}</h1>
    <p>{`ウェブアプリ・ネイティブアプリ(このエントリーでは iOS/Android 各プラットフォーム固有の言語を使って開発したものを指しています)それぞれにメリットデメリットはあると思いますが、ゲームのようなグラフィックごりごりのものでなければウェブアプリで十分に感じています。`}</p>
    <p>{`最近では、両方に展開しているもののネイティブアプリにコストを割いてしまっているのか、モバイルのウェブアプリの UI が本当にひどかったり最適化されていないと感じる例があります。最適化もしてないし、導線はネイティブアプリに向けてるのにネイティブアプリの方が使われてるじゃん！って言われてもウェブアプリの気持ちになると「そりゃそうでしょ。。。」って感じです笑`}</p>
    <p>{`とはいえ、いくらウェブアプリが好きでもネイティブアプリを作らざるをえないとなった場合に iOS/Android それぞれ作ることが適切なのかなと思ってしまいます。そこでクロスプラットフォーム開発できるものを探していると、自分のスキルセットに合いそうなものがいくつかありました。その中で今回は、Ionic を触ってみることにしました。`}</p>
    <p>{`今回 TechLunch で発表したスライドは以下です。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/player/2e269264c45c4511b972bca1f04fda80" title="フロントエンジニアが Ionic を触ってみた /lonic" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "560px",
      "height": "314px"
    }} data-ratio="1.78343949044586"></iframe>
    <p>{`スライドと重複してる箇所もありますが、テキストでも解説してみます。ご興味がありましたら以下もどうぞ。`}</p>
    <h1>{`ウェブアプリとネイティブアプリ`}</h1>
    <p>{`ウェブアプリとネイティブアプリはよく比較されますが、その違いは以下のような感じかなと思います。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`項目`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ウェブアプリ`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ネイティブアプリ`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`動作速度`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`遅め`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`早め`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`デバイスの機能`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`使えるものもある`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`利用可`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`開発コスト`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`普通`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`多め`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`審査`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`無し`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`有り`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`インストール`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`不要`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`必要`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`動作速度は遅めとはいえ、ゲームではなく EC サイトのようなものであれば、そこまで気にするほどではない思います（作りにもよると思いますが）。デバイスの機能に関しては Chrome なら割と使えます。開発コストはワンソースでいけるのでネイティブアプリよりは優れているかなと思います。`}</p>
    <p>{`あとは何と言っても、ブラウザと URL さえあればどこからでも使えるっていうのがメリットです。最近ですとウェブアプリをネイティブアプリっぽく動かす Progressive Web Application（以下 PWA）というワードもよく目にするようになりました。PWA は動作速度の面やプッシュ通知・オフラインでも利用できたりとウェブアプリの弱点を補ってくれるのですが、PWA に必要な技術である Service Worker の実装がされていなかったりと環境により十分に恩恵を受けることができない場合があります。`}</p>
    <p>{`個人的に以下のようなパターンの場合`}</p>
    <ul>
      <li parentName="ul">{`ネイティブの実装が必要`}</li>
      <li parentName="ul">{`重要だけど機能に更新がはいることがほぼない`}</li>
      <li parentName="ul">{`アプリを使う上で毎回必要なわけではない`}</li>
    </ul>
    <p>{`アプリによっては、この部分だけアプリに切り出して、ウェブアプリで必要になったら呼び出すとかでもいいのかなと思います。`}</p>
    <p>{`上記のようなことをネイティブアプリとして実現できるものに Apach Cordova（以下 Cordova）があります。ウェブアプリは WebView（ネイティブアプリ内でウェブページを表示する部品みたいなもの）に表示し、ネイティブ機能はプラグインとして提供されているのでそれを JavaScript から呼び出すことが可能です。`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://ionicframework.com/"
      }}>{`Ionic`}</a>{`とは`}</h1>
    <p>{`ウェブの技術を用いてネイティブアプリの開発を可能にするフレームワークです。
主な特徴は以下です。`}</p>
    <ul>
      <li parentName="ul">{`オープンソース`}</li>
      <li parentName="ul">{`Apach Cordova 上に構築されている`}</li>
      <li parentName="ul">{`HTML5 を用いたクロスプラットフォームなハイブッドアプリ(PWA 含む)の開発が可能`}</li>
      <li parentName="ul">{`JavaScript のフレームワークは Angular`}</li>
      <li parentName="ul">{`Angular を採用しているので AltJS は TypeScript`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ionicframework.com/docs/components"
        }}>{`​​UI コンポーネント`}</a>{`がいい感じ`}</li>
      <li parentName="ul">{`ネイティブ機能をフルで利用可`}</li>
    </ul>
    <h1>{`実際に触ってみた`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ionicframework.com/getting-started/"
      }}>{`公式の Get started`}</a>{`にもあるようにブラウザでアプリを表示するまでは 3 ステップです。
※Node.js がインストールされていることが前提`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 1. ionic と cordova のインストール(この時の Ionic CLI は 3.18.0)`}</span>{`
 $ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -g cordova ionic

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 2. アプリケーションの作成`}</span>{`
$ ionic start `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`アプリ名`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`テンプレート`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 3. アプリケーション起動`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`アプリ名`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
$ ionic serve`}</code></pre></div>
    <p><a parentName="p" {...{
        "href": "https://ionicframework.com/docs/cli/starters.html"
      }}>{`テンプレート`}</a>{`もよく見る形のものは用意されているのでこだわらなければサクッとそれっぽいものが作れます。下の画像は tabs テンプレートを使用しました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171122/20171122191851.gif",
      "alt": "20171122191851.gif"
    }}></img>
    <p>{`Android/iOS の開発環境が整った状態ですと以下のコマンドで、エミュレータを起動しネイティブアプリとしてインストールすることができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# 1. 対象の OS を追加`}</span>{`
$ ionic cordova platform `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`ios/android`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 2. エミュレータ起動かつインストール`}</span>{`
$ ionic cordova run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`ios/android`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 2.5 ウェブアセット更新と連動してアプリ更新させる場合`}</span>{`
$ ionic cordova run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`ios/android`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` --livereload`}</code></pre></div>
    <p>{`ここまでは本当に簡単です。起動時に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--livereload`}</code>{`オプションをつけるとウェブのアセットが更新されるとアプリ内の画面も更新されるので便利です。ネイティブの機能を利用したくなった場合も多くのプラグインが提供されているのでウェブの知識だけでもある程度のアプリは作れると思います。`}</p>
    <p>{`便利そうだし簡単にアプリ作れそう！となりますが、やりたいことがプラグインで提供されていなかった場合は自分で作成しないといけません。プラグインの作成は iOS/Android それぞれで作らないといけないため知識もそれぞれ必要です。そして、このプラグインを作るのがウェブの知識だけだと割と苦戦します。`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://webrtc.ecl.ntt.com/"
      }}>{`SkyWay`}</a>{`を利用してビデオチャットアプリを作ってみた`}</h1>
    <p>{`SkyWay とは NTT コミュニケーションズが提供している WebRTC を利用したビデオチャット等ができるサービスです。今回この`}<a parentName="p" {...{
        "href": "https://webrtc.ecl.ntt.com/"
      }}>{`SkyWay`}</a>{`を利用して、Android のみですがビデオチャットアプリを作ってみました。`}</p>
    <h3>{`Android プラグイン作成`}</h3>
    <p>{`SkyWay のプラグインがなかったのでプラグインを作成します。画面のあるプラグインの作成に結構はまりました（基本的なプラグイン作成の説明は、検索すればすぐに見つかると思うので省略させていただきます）。解決して思うようには動いたのですが、これでいいのかわかりません。いい感じの解決法があったら教えてください。`}</p>
    <p>{`今回は`}<a parentName="p" {...{
        "href": "https://github.com/skyway/skyway-android-sdk/tree/master/examples"
      }}>{`SkyWay の Android サンプルコード`}</a>{`に少し手を入れて利用させて頂きプラグインを作成してみました。ざっくりディレクトリ構成は以下のようになりました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`    plugin-skyway
    ├── platform
    │   └── android
    │       └── AndroidStudio で作成したプロジェクト`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SkyWay サンプルソース`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    └── plugin
        ├── package.json
        ├── plugin.xml
        ├── src
        │   ├── android
        │   │   ├── cordova-plugin-skyway.gradle
        │   │   ├── MainActivity.java
        │   │   ├── PeerListDialogFragment.java
        │   │   ├── SkyWay.java
        │   │   ├── layout
        │   │   │   ├── activity_main.xml
        │   │   │   └── fragment_dialog_peerlist.xml
        │   │   └── libs
        │   │       └── skyway.aar
        │   └── ios
        └── www
            └── SkyWay.js`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin-skyway/platform`}</code>{`に各 OS のプラグイン用プロジェクトがある感じです。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin-skyway/plugin`}</code>{`が Ionic のプロジェクトにインストールされるディレクトリです。`}</p>
    <p>{`はじめは、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin-skyway/plugin/src/android`}</code>{`に AndroidStudio で作成したプロジェクトを置いてました。しかし、Ionic のプロジェクトに作成したプラグインをスンストールすると不要なものまで含まれてしまったので、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin-skyway/platform/android`}</code>{`にプロジェクトを作成し必要なファイルのみを`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin-skyway/plugin/src/android`}</code>{`にコピーすることにしました。`}</p>
    <p>{`AndroidStudio でプラグインを開発する際は、一度 Ionic のプロジェクトを Android でビルドし、Ionic プロジェクト内の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`platforms/android/CordovaLib/build/outputs/aar/CordovaLib-debug.aar`}</code>{`をプラグインのプロジェクトで取り込まないといけないようです。`}</p>
    <p>{`いざ Ionic プロジェクトにインストールしてビルドすると ConstraintLayout が無いとか SkyWay の SDK のバージョンがどうとか怒られますが、Android の開発の仕組みが理解できていなかったので下記の「パッケージ R は存在しません」というエラーに時間を取られました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`./MainActivity.java:258: エラー: パッケージ R は存在しません
                    Canvas canvas `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Canvas`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` findViewById`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`R.id.svLocalView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`のように R.java がないと怒られます。
AndroidStudio から GUI でポチポチと画面を作ると R.java というのができいい感じに解決してくれるようなのですが、今回のように xml のみを移動させるだけだと R.java が作られません。`}</p>
    <p>{`そこで`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`R.id.svLocalView`}</code>{`のような`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`R.`}</code>{`の箇所を下記のように置き換えて、やっとビルドが通るようになりました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`getResources`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getIdentifier`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"svLocalView"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getPackageName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`最終的に以下のようなものができました。
SkyWay ボタンをタップするとネイティブの画面が立ち上がり、Android の SkyWay SDK を利用してテレビ電話をすることができます。
クローズボタンをタップするとネイティブ画面が終了し、WebView 部分に戻ってきます。実際触ってみた感じフルネイティブアプリと違和感なく感じます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20171122/20171122192030.gif",
      "alt": "20171122192030.gif"
    }}></img>
    <p><em parentName="p">{`※アニメーション GIF の容量が重くなったので相手側でのコード入力中の空白時間を削ったり編集した影響で左下の緑と赤の画面が飛んだりしてますが、実際は滑らかです`}</em></p>
    <h1>{`まとめ`}</h1>
    <p>{`今回触ってみたというより指先が触れた程度ですが、全然 Ionic でもいけそうな雰囲気を感じました。普通に触ってる分にはネイティブ部分なのかウェブ部分なのかわからなかったです。ただし、銀の弾丸ではないので、実際に開発に導入する場合はメリット・デメリットをちゃんと把握する必要があります。
ウェブアプリ寄りのことを言ってはきたものの、結局はウェブだろうがネイティブだろうがどんなツールを使おうが、`}<strong parentName="p">{`使ってくれる人が求めるもの`}</strong>{`を提供できればいいと思います。今後も何かあったときの引き出しのために色々なツールなど触っていこうと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`12/6（水）、こんなイベント（というか飲み会）をやります。
今回のブログの話が詳しく聞きたい、医療ヘルスケア領域の開発ってどんな感じだろう、社会貢献性の高いプロダクトに関わりたい、など思っているエンジニア・デザイナーの方、ビール片手に開発の話で盛り上がりませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/projects/170112"
      }}>{`https://www.wantedly.com/projects/170112`}</a></p>
    <p>{`その日は予定が入っているんだけど話を聞きたいという方は、こちらの「話を聞きたい」ボタンからどうぞ。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`https://www.wantedly.com/companies/medley`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      